import Swiper from '../../vendors/swiper';

/**
 * Swiper: Partner reviews
 * lazy global const is called with revalidate function
 * Swiper lazyload is not used as it does not lazyload any images on first slide
 * An IntersectionObserver is to to ensure the slides only scroll when in viewport
 */

/* Which media query
************************************************************* */

function swiperInit(element) {
  const data = element.dataset;
  if (Number(data.mobile_off) && window.isSmall()) {
    return;
  }
  // eslint-disable-next-line no-new
  new Swiper(element, {
    slidesPerView: !data.slidesPerView ? 1.5 : data.slidesPerView,
    spaceBetween: !data.slidesSpaceBetween ? 16 : parseInt(data.slidesSpaceBetween),
    slidesPerGroup: 1,
    navigation: !data.hideNavigation ? {
      nextEl: '.swiper-btn--next',
      prevEl: '.swiper-btn--prev',
      disabledClass: 'swiper-btn--disabled',
    } : false,
    pagination: data.pagination === 'true' && {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    scrollbar: data.scrollbar === 'true' && {
      el: '.swiper-scrollbar',
      draggable: true,
    },
    breakpoints: {
      // >=mobile
      768: {
        slidesPerView: !data.slidesPerViewMd ? 2.5 : parseFloat(data.slidesPerViewMd),
        spaceBetween: !data.slidesSpaceBetweenMd ? 24 : parseInt(data.slidesSpaceBetweenMd),
      },
      // >=tablet
      1201: {
        slidesPerView: !data.slidesPerViewLg ? 6 : parseFloat(data.slidesPerViewLg),
        spaceBetween: !data.slidesSpaceBetweenLg ? 24 : parseInt(data.slidesSpaceBetweenLg),
      },
    },
  });
}

export function initCarouselSwiper() {
  // Get all swiper elements to be initialised
  const swiperToplists = document.querySelectorAll('.js-swiper-carousel');
  swiperToplists.forEach((element) => {
    swiperInit(element);
  });
}

(function () {
  initCarouselSwiper();
}());

function pingPong(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

// Call initSwiper function via debounce function.
// 200ms is roughly each frame duration when we resize on a 60hz screen
window.addEventListener('resize', pingPong(initCarouselSwiper, 200));
